let $data;
let $puzzle_btns;
let $lightbox;
let $lightbox_imagebox;
let $close_button;

let $previous_button;
let $next_button;

let $span_current;
let $span_total;

let $index = 0;


const main = () => {
    prepareDOMElements();
    prepareDOMEvents();
}

const prepareDOMElements = () => {
    $data = document.querySelector('#data');
    $puzzle_btns = document.querySelectorAll('.puzzle__button');
    $lightbox = document.querySelector('.lightbox');
    $lightbox_imagebox = document.querySelector('.lightbox__imagebox');
    $close_button = $lightbox.querySelector('.lightbox__close');
    
    $previous_button = document.querySelector('.lightbox__button--previous');
    $next_button = document.querySelector('.lightbox__button--next');
    
    $span_current = document.querySelector('.lightbox__text--current');
    $span_total = document.querySelector('.lightbox__text--total');
};

const prepareDOMEvents = () => {
    $close_button.addEventListener('click', close_lightbox);
    $next_button.addEventListener('click', next_image);
    $previous_button.addEventListener('click', previous_image);
    $puzzle_btns.forEach(button => {
        button.addEventListener('click', open_lightbox)
    })
};

const open_lightbox = (e) => {
        // get closest puzzle to clicked button
        const chosen_puzzle = e.target.closest('div');

        // get .puzzle__links placed inside the puzzle
        const data_links = chosen_puzzle.querySelectorAll('.puzzle__links');

        // get src and srcset, create images and assign taken src and srcset
        for (let i = 0; i < data_links.length; i++) {
            const src = data_links[i].getAttribute('src');
            const srcset = data_links[i].getAttribute('srcset');
            const sizes = "80vw"

            const image = document.createElement('img');
            image.setAttribute('src', src);
            image.setAttribute('srcset', srcset);
            image.setAttribute('sizes', sizes);
            image.classList.add('lightbox__image');
            $lightbox_imagebox.appendChild(image); 

            if (i == 0) {
                image.classList.add('lightbox__image--visible');
            }

            // get total no of images and assign to lightbox bar
            const count_images = data_links.length;
            $span_total.innerHTML = data_links.length;

            // activate lightbox
            $lightbox.classList.add('lightbox--active');
        }
};

const close_lightbox = () => {
    $lightbox.classList.remove('lightbox--active');
    for (let i = $lightbox_imagebox.children.length -1; i >= 0; i--) {
        $lightbox_imagebox.children[i].outerHTML = ""
    }
    $span_current.innerHTML = 1;
    $index = 0;
};

const next_image = () => {
    if ($index + 1 < $lightbox_imagebox.children.length) {
        $lightbox_imagebox.children[$index].classList.remove('lightbox__image--visible');
        $index++;
        $lightbox_imagebox.children[$index].classList.add('lightbox__image--visible');
        $span_current.innerHTML = $index + 1;
    }
};

const previous_image = () => {
    if ($index + 1 <= $lightbox_imagebox.children.length && $index > 0) {
        $lightbox_imagebox.children[$index].classList.remove('lightbox__image--visible');
        $index--;
        $lightbox_imagebox.children[$index].classList.add('lightbox__image--visible');
        $span_current.innerHTML = $index + 1;
    }
};

document.addEventListener('DOMContentLoaded', main);